<template>
  <main class="page-login">
    <div id="printContainer" class="container">
      <!-- <video class="bg" autoplay="" loop="" muted="" preload="">
        <source src="" type="video/mp4">
      </video> -->
      <div class="login-container clear">
        <!-- <div class="slogon">
          <div class="slogon-text">
            DBS
          </div>
          <div class="slogon-text small">
            is the
          </div>
          <div class="slogon-text">
            Best
          </div>
        </div> -->
        <div class="login-form">
          <el-card class="form-container">
            <div slot="header" class="align-center">
              <router-link to="/">
                <img class="login-logo" src="@/assets/images/logo-login.png" alt="logo"/>
              </router-link>
            </div>
            <div
              :is="formComponent"
              @handleFormChange="changeForm">
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <MainPageFloatButton>
    </MainPageFloatButton>
  </main>
</template>

<script>
import { mapMutations } from 'vuex'
import * as apis from '@/apis/index.ts'
import TheLoginForm from '@/components/TheLoginForm/TheLoginForm.vue'
import TheSignupForm from '@/components/TheSignupForm/TheSignupForm.vue'
import TheForgetPwdForm from '@/components/TheForgetPwdForm/TheForgetPwdForm.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'

export default {
  name: 'login',
  components: {
    TheLoginForm,
    TheSignupForm,
    TheForgetPwdForm,
    MainPageFloatButton
  },
  data () {
    return {
      formComponent: TheLoginForm
    }
  },
  methods: {
    ...mapMutations([
      'mutationIsFullPageLoading',
      'mutationErrorCodes'
    ]),
    changeForm (componentName) {
      if (componentName === 'LoginForm') {
        this.formComponent = 'TheLoginForm'
      } else if (componentName === 'SignupForm') {
        this.formComponent = 'TheSignupForm'
      } else if (componentName === 'ForgetPwdForm') {
        this.formComponent = 'TheForgetPwdForm'
      }
    }
  },
  created () {
    this.mutationIsFullPageLoading(false)

    // 將網址修改為 '/'
    window.history.replaceState(window.history.state, '', '/')

    apis.errorCodes()
      .then(result => {
        this.mutationErrorCodes(result.payload)
      })
  },
}
</script>
