<template>
  <el-form
    :model="loginForm"
    :rules="loginFormRules"
    ref="loginForm"
    @submit.native.prevent>
    <el-form-item prop="email">
      <div slot="label">
        <i class="fas fa-envelope" placeholder="請輸入"></i><span>電子郵件</span>
      </div>
      <el-input
        type="text"
        v-model="loginForm.email"
        @keyup.enter.native="submitLoginForm">
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <div slot="label">
        <i class="fas fa-lock"></i><span>密碼</span>
      </div>
      <el-input
        type="password"
        v-model="loginForm.password"
        placeholder="請輸入"
        @keyup.enter.native="submitLoginForm">
      </el-input>
    </el-form-item>
    <!-- <el-form-item>
      <el-checkbox v-model="isLoginKept">記住我</el-checkbox>      
    </el-form-item> -->
    <el-form-item>
      <el-button
        type="primary"
        class="login-btn"
        :loading="isLoading"
        @click="submitLoginForm">
        登入
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-row>
        <el-col :span="12" class="align-left">
          <a
            href="javascript:;"
            @click="changeForm('SignupForm')">
            點此註冊
          </a>
        </el-col>
        <el-col :span="12" class="align-right">
          <a
            href="javascript:;"
            @click="changeForm('ForgetPwdForm')">
            忘記密碼
          </a>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import Cookies from 'js-cookie'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      isLoading: false,
      loginForm: {
        email: '',
        password: ''
      },
      // 表單規則
      loginFormRules: {
        email: [
          { required: true, message: '請輸入電子郵件', trigger: 'blur' },
          { type: 'email', message: '請輸入電子郵件正確格式', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '請輸入密碼', trigger: 'blur' }
        ]
      },
      isLoginKept: true // 預設記住帳號登入
    }
  },
  methods: {
    ...mapMutations([
      'mutationIsFullPageLoading',
      'mutationIsLoginKept'
    ]),
    ...mapActions([
      'actionLogin'
    ]),
    ...mapState([
      'userInfo'
    ]),
    async submitLoginForm () {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid === false) {
          this.$message({
            message: '請輸入完整的email及密碼',
            type: 'error'
          })
          return false
        }
        // 是否記住登入狀態
        this.mutationIsLoginKept(this.isLoginKept)
        // 登入
        this.isLoading = true
        let result = await this.actionLogin({
          email: this.loginForm.email,
          password: this.loginForm.password
        })
        this.isLoading = false
        if (result && result.success === true) {
          this.mutationIsFullPageLoading(true)
          this.$router.push({
            name: 'home'
          })
        }
        // else {
        //   this.$message({
        //     message: (result && result.message) ? result.message : '帳號或密碼錯誤',
        //     type: 'error'
        //   })
        // }
      })
    },
    changeForm (componentName) {
      this.$emit('handleFormChange', componentName)
    }
  },
  created () {
    // 是否紀錄登入狀態
    let isLoginKept = Cookies.get('bp__dbs__isLoginKept')
    if (isLoginKept == true || isLoginKept == 'true') {
      this.isLoginKept = true
    }
  }
}
</script>
