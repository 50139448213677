<template>
  <el-form
    :model="forgetPwdForm"
    :rules="formRules"
    ref="forgetPwdForm"
    @submit.native.prevent>
    <el-form-item>
      請輸入您註冊時使用的email以領取新密碼
    </el-form-item>
    <el-form-item prop="email">
      <div slot="label">
        <i class="fas fa-envelope" placeholder="請輸入"></i><span>電子郵件</span>
      </div>
      <el-input v-model="forgetPwdForm.email" type="text"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        class="login-btn"
        :loading="isLoading"
        @click="submitForm">
        送出
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-row>
        <el-col :span="12" class="align-left">
          <div
            style="cursor:pointer"
            @click="changeForm('LoginForm')">
            返回
          </div>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import * as apis from '@/apis/index.ts'

export default {
  data () {
    return {
      isLoading: false,
      forgetPwdForm: {
        email: ''
      },
      // 表單規則
      formRules: {
        email: [
          { required: true, message: '請輸入電子郵件', trigger: 'blur' },
          { type: 'email', message: '請輸入電子郵件正確格式', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    async submitForm () {
      this.$refs.forgetPwdForm.validate(async (valid) => {
        if (valid === false) {
          // this.$message({
          //   message: '請輸入完整的email及密碼',
          //   type: 'error'
          // })
          return false
        }
        this.isLoading = true
        const result = await apis.userForgetPassword({
          email: this.forgetPwdForm.email
        })
        if (!result || result.success == false) {
          return
        }
        this.changeForm('loginForm')
      })
    },
    changeForm (componentName) {
      this.$emit('handleFormChange', componentName)
    }
  }
}
</script>
