<template>
  <el-form
    ref="signupForm"
    label-position="top"
    :model="signupForm"
    :rules="formRules"
    @submit.native.prevent>
    <el-form-item>
      註冊新帳號
    </el-form-item>
    <el-form-item label="所屬單位" prop="unit">
      <el-select v-model="signupForm.unit" placeholder="請選擇" style="width:100%">
        <el-option
          v-for="item in unitOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="姓名" prop="name">
      <el-input v-model="signupForm.name" type="text"></el-input>
    </el-form-item>
    <el-form-item label="電子郵件" prop="email">
      <el-input v-model="signupForm.email" type="email"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        class="login-btn"
        :loading="isLoading"
        @click="submitForm">
        送出
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-row>
        <el-col :span="12" class="align-left">
          <div
            style="cursor:pointer"
            @click="changeForm('LoginForm')">
            返回
          </div>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import * as apis from '@/apis/index.ts'

export default {
  data () {
    return {
      isLoading: false,
      unitOptions: [],
      signupForm: {
        unit: null,
        name: '',
        email: ''
      },
      // 表單規則
      formRules: {
        unit: [
          { required: true, message: '請選擇單位', trigger: 'change' },
        ],
        name: [
          { required: true, message: '請輸入姓名', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '請輸入電子郵件', trigger: 'blur' },
          { type: 'email', message: '請輸入電子郵件正確格式', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    async submitForm () {
      this.$refs.signupForm.validate(async (valid) => {
        if (valid === false) {
          // this.$message({
          //   message: '請輸入完整的email及密碼',
          //   type: 'error'
          // })
          return false
        }
        this.isLoading = true
        const result = await apis.userRegister({
          unit: this.signupForm.unit,
          name: this.signupForm.name,
          email: this.signupForm.email
        })
        this.isLoading = false

        if (result && result.success == true) {
          this.$message({
            message: '已送出申請',
            type: 'success'
          })
          this.changeForm('LoginForm')
        }

      })
    },
    changeForm (componentName) {
      this.$emit('handleFormChange', componentName)
    }
  },
  created () {
    apis.units()
      .then(data => {
        if (data && data.payload) {
          this.unitOptions = data.payload.map(d => {
            return {
              label: d.unit,
              value: d.unit
            }
          })
        }
      })
  }
}
</script>
